import packageInfo from '../../package.json';

export const environment = {
  production: false,
  hmr: false,
  version: packageInfo.version + '-stage',
  envName: 'STAGE',
  environmentName: 'STAGE',
  origins: ['https://portal-stage.archinsurance.com', 'https://aip-base-stage.apps.aks-nonprod.archinsurance.com'],
  aipHostPort: 'https://stgmy.archinsurance.com/',
  aipHostPort1: 'https://portal-stage.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://aip-authorization-ms-stage.apps.aks-nonprod.archinsurance.com',
  aipRedirectUrl: 'https://stgmy.archinsurance.com',
  aipAdminRedirectUrl: 'https://portal-stage.archinsurance.com',
  aipUserProfileRedirectUrl: 'https://stgmy.archinsurance.com',
  oldplportalurl: 'https://stgmy.archinsurance.com/daspl4',
  quickClaimsUrl: 'https://claims-ui-stage.apps.aks-nonprod.archinsurance.com/claims/#/claims/report-claim',
  aipHostPortAdmin: 'https://portal-stage.archinsurance.com/aip-admin-api',
  appUrl: 'https://portal-stage.archinsurance.com/',
  AUTH_URL: 'https://archinsurance-dev.auth0.com/oauth/token',
  archConnectHost: '/', //for use with api proxy on local

  //ENV specific variables
  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: true,
  SKIP_ENV_ROLE_CHECK: true,
};
