import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from '@app/authentication/authentication.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StatusModule } from './status/status.module';
import { StatusShellModule } from './status/shell/shell.module';
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CoreModule,
    AuthenticationModule.forRoot(),
    TranslateModule.forRoot(),
    // NgbModule,
    SharedModule,
    ShellModule,
    HomeModule,
    StatusShellModule,
    //StatusModule,
    LoginModule,
    FlexLayoutModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
