import packageInfo from '../../package.json';

export const environment = {
  production: true,
  hmr: false,
  version: packageInfo.version,
  envName: 'PROD',
  environmentName: 'PROD',
  origins: ['https://portal.archinsurance.com'],
  aipHostPort: 'https://my.archinsurance.com/',
  aipHostPort1: 'https://portal.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://portal.archinsurance.com/api-menu',
  aipRedirectUrl: 'https://my.archinsurance.com',
  aipAdminRedirectUrl: 'https://portal.archinsurance.com',
  aipUserProfileRedirectUrl: 'https://my.archinsurance.com',
  oldplportalurl: 'https://my.archinsurance.com/daspl4',
  quickClaimsUrl: 'https://portal.archinsurance.com/claims/#/claims/report-claim',
  aipHostPortAdmin: 'https://portal.archinsurance.com/aip-admin-api',
  appUrl: 'https://portal.archinsurance.com/',
  AUTH_URL: 'https://archinsurance.auth0.com/oauth/token',
  archConnectHost: 'https://archconnect.archinsurance.com/',

  //ENV specific variables
  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: false,
  SKIP_ENV_ROLE_CHECK: true,
};
